import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Meta } from '@mentimeter/ragnar-ui/typography';

export function SSOFormTermsAndPolicies() {
  return (
    <Box mt={2} mb={4}>
      <Meta as="p" textAlign="center">
        By logging in you accept our{' '}
        <Link
          color="inherit"
          href="/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of use
        </Link>{' '}
        and{' '}
        <Link
          color="inherit"
          href="/policies"
          target="_blank"
          rel="noopener noreferrer"
        >
          policies
        </Link>
        .
      </Meta>{' '}
    </Box>
  );
}
