import { Users } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';

export default function SSOIcon() {
  return (
    <Box
      bg="#F0F5FA"
      alignItems="center"
      justifyContent="center"
      borderRadius={2}
      p={3}
    >
      <Box width={80}>
        <Users />
      </Box>
    </Box>
  );
}
